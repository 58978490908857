:root {
  --font-family-primary: "JetBrains Mono", monospace;
  --font-family-secondary: "Arial", sans-serif;
  --font-family-emphasis: "Cinzel", serif;
  --font-size-body: clamp(1.2rem, 2vw, 1.6rem);
  --font-size-h1: clamp(2rem, 5vw, 2.8rem);
  --font-size-h2: clamp(1.6rem, 4vw, 2.2rem);
  --font-size-h3: clamp(1.3rem, 3vw, 1.9rem);
  --font-size-caption: clamp(.8rem, 2vw, .9rem);
  --font-size-button: clamp(1rem, 2.5vw, 1.05rem);
  --font-size-tooltip: clamp(.7rem, 1.5vw, .9rem);
  --line-height-main: 1.8;
  --color-bg-main: #1e1e1e;
  --color-bg-content: #2a2a2a;
  --color-text-main: #e0e0e0;
  --color-text-alt: #ccc;
  --color-text-content: #fff;
  --color-positive: #e07a5f;
  --color-negative: #d62828;
  --color-neutral: #3d405b;
  --color-bg-valence-positive: #e07a5f33;
  --color-bg-valence-negative: #d6282833;
  --color-bg-valence-neutral: #3d405b33;
  --feedback-color: var(--color-negative);
  --color-shadow-dark: #00000080;
  --color-bg-emphasis: #0000000d;
  --color-bg-whisper: #2828280d;
  --color-bg-intrigue: #2a9d8f1a;
  --color-hover-bg: #0083b033;
  --color-accent-main: #f4f1de;
  --color-accent-alt: #c9ada7;
  --color-accent-gradient: linear-gradient(45deg, #f4f1de, #c9ada7);
  --color-primary: #3d405b;
  --color-text-neutral: var(--color-neutral);
  --color-text-negative: #d62828;
  --transition-speed-fast: .2s;
  --transition-speed-medium: .3s;
  --transition-speed-slow: .5s;
  --transition-ease: ease;
  --transition-function: ease-in-out;
  --spacing-xs: .3rem;
  --spacing-sm: .6rem;
  --spacing-md: 1.2rem;
  --spacing-lg: 1.8rem;
  --spacing-xl: 2.4rem;
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-width-thin: 1px;
  --border-width-medium: 2px;
  --shadow-sm: 0 1px 3px #0000004d;
  --shadow-md: 0 3px 6px #0006;
  --shadow-lg: 0 5px 15px #0006;
  --shadow-hover: 0 6px 12px #0000004d;
  --z-index-tooltip: 10;
  --z-index-overlay: 500;
  --z-index-modal: 1000;
  --z-index-dropdown: 200;
  --animation-duration-short: .3s;
  --animation-duration-medium: .6s;
  --animation-duration-long: 1s;
  --bg-color-main: #f5deb2;
  --accent-color-main: teal;
  --max-width-content: calc(min(70vw, 100%));
  --interactive-hover-scale: 1.05;
  --interactive-active-scale: .98;
  --interactive-focus-outline: 2px dashed var(--color-accent-alt);
  --color-bg-main-light: #fff;
  --color-bg-content-light: #f9f9f9;
  --color-text-main-light: #1e1e1e;
  --color-text-alt-light: #555;
  --color-text-content-light: #000;
}

:focus {
  outline: var(--interactive-focus-outline);
  outline-offset: 4px;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-family: var(--font-family-primary);
}

body {
  min-height: 100vh;
  padding: var(--spacing-xl) var(--spacing-lg);
  background-color: var(--color-bg-main);
  font-size: var(--font-size-body);
  line-height: var(--line-height-main);
  color: var(--color-text-main);
  transition: background-color var(--transition-speed-medium) var(--transition-function);
  flex-direction: column;
  display: flex;
}

a {
  color: var(--color-primary);
  transition: color var(--transition-speed-fast) var(--transition-function);
  text-decoration: none;
  position: relative;
}

a:after {
  content: "";
  background: var(--color-accent-alt);
  width: 0;
  height: 2px;
  transition: width var(--transition-speed-fast) var(--transition-function);
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
}

a:hover:after, a:focus:after {
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-primary);
  color: var(--color-text-main);
  margin-bottom: var(--spacing-sm);
  line-height: 1.2;
}

h1 {
  font-size: var(--font-size-h1);
  color: var(--color-accent-alt);
  text-align: center;
  position: relative;
}

h1:before {
  content: "";
  background: var(--color-accent-alt);
  width: 50px;
  height: 3px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

h2 {
  font-size: var(--font-size-h2);
  color: var(--color-accent-main);
}

p {
  margin-bottom: var(--spacing-md);
  line-height: 1.8;
}

blockquote {
  border-left: var(--border-width-medium) solid var(--color-accent-main);
  padding-left: var(--spacing-md);
  color: var(--color-text-alt);
  margin: var(--spacing-lg) 0;
  background-color: var(--color-bg-emphasis);
  border-radius: var(--border-radius-sm);
  transition: background-color var(--transition-speed-fast) var(--transition-function), border-color var(--transition-speed-fast) var(--transition-function);
  font-style: italic;
  position: relative;
}

blockquote:before {
  content: open-quote;
  color: var(--color-accent-main);
  font-size: 2rem;
  position: absolute;
  top: -10px;
  left: -10px;
}

main.chapter {
  max-width: var(--max-width-content);
  width: 100%;
  margin: var(--spacing-xl) auto;
  padding: var(--spacing-lg);
  background-color: var(--color-bg-content);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  transition: box-shadow var(--transition-speed-fast) var(--transition-function), transform var(--transition-speed-fast) var(--transition-function);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  animation: .4s ease-in-out fadeIn;
  display: flex;
}

main.chapter:hover {
  box-shadow: var(--shadow-lg);
  transform: translateY(-2px);
}

figure {
  margin: var(--spacing-lg) 0;
  text-align: center;
  max-width: calc(min(70vw, 80%));
}

figure img {
  border-radius: var(--border-radius-md);
  max-width: 100%;
  height: auto;
  box-shadow: var(--shadow-sm);
  transition: transform var(--transition-speed-fast) var(--transition-function), box-shadow var(--transition-speed-fast) var(--transition-function);
  cursor: pointer;
  object-fit: cover;
  max-height: 60vh;
}

figcaption {
  font-size: var(--font-size-caption);
  color: var(--color-text-alt);
  margin-top: var(--spacing-sm);
  text-align: center;
}

nav.chapter-navigation, .section-navigation {
  margin-bottom: var(--spacing-lg);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-sm);
  width: 100%;
  display: flex;
}

nav.chapter-navigation button, .section-navigation button, .additional-links a, .primary-action, #lore-button {
  background: var(--color-accent-gradient);
  color: var(--color-text-content);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  cursor: pointer;
  font-size: var(--font-size-button);
  transition: background-color var(--transition-speed-fast) var(--transition-function), transform var(--transition-speed-fast) var(--transition-function), box-shadow var(--transition-speed-fast) var(--transition-function);
  border: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

nav.chapter-navigation button:hover, nav.chapter-navigation button:focus, .section-navigation button:hover, .section-navigation button:focus, .additional-links a:hover, .additional-links a:focus, .primary-action:hover, .primary-action:focus, #lore-button:hover, #lore-button:focus {
  background-color: var(--color-accent-alt);
  box-shadow: var(--shadow-lg);
  transform: translateY(-2px);
}

nav.chapter-navigation button:active, .section-navigation button:active, .primary-action:active {
  box-shadow: var(--shadow-sm);
  transform: translateY(1px);
}

.additional-links {
  margin-top: var(--spacing-md);
  gap: var(--spacing-sm);
  flex-wrap: wrap;
  display: flex;
}

.additional-links a {
  padding: var(--spacing-sm) var(--spacing-md);
  background: var(--color-accent-main);
  border-radius: var(--border-radius-md);
  transition: background-color var(--transition-speed-fast) var(--transition-function), transform var(--transition-speed-fast) var(--transition-function), box-shadow var(--transition-speed-fast) var(--transition-function);
  color: var(--color-text-content);
  text-decoration: none;
  display: inline-block;
}

.additional-links a:hover, .additional-links a:focus {
  background-color: var(--color-accent-alt);
  transform: scale(var(--interactive-hover-scale));
  box-shadow: var(--shadow-hover);
}

.primary-action {
  width: 100%;
  margin-top: var(--spacing-md);
  background: var(--color-accent-gradient);
  text-align: center;
  transition: background-color var(--transition-speed-fast) var(--transition-function), transform var(--transition-speed-fast) var(--transition-function), box-shadow var(--transition-speed-fast) var(--transition-function);
  display: block;
}

#lore-button {
  background: var(--color-accent-gradient);
}

#lore-button:hover, #lore-button:focus {
  background-color: var(--color-accent-alt);
}

#lore-collector {
  margin-bottom: var(--spacing-md);
  padding: 0;
  list-style: none;
  display: none;
}

#lore-collector li {
  margin-bottom: var(--spacing-xs);
  padding: var(--spacing-xs);
  background-color: var(--color-hover-bg);
  border-radius: var(--border-radius-sm);
  transition: background-color var(--transition-speed-fast) var(--transition-function);
}

#lore-collector li:hover {
  background-color: var(--color-bg-valence-neutral);
}

custom-puzzle, custom-reflection, custom-mirror, custom-quiz, custom-tooltip, custom-rating {
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
  background-color: var(--color-bg-content);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-speed-fast) var(--transition-function), box-shadow var(--transition-speed-fast) var(--transition-function), background-color var(--transition-speed-fast) var(--transition-function);
  display: block;
}

custom-puzzle:hover, custom-reflection:hover, custom-mirror:hover, custom-quiz:hover, custom-tooltip:hover, custom-rating:hover, custom-puzzle:focus, custom-reflection:focus, custom-mirror:focus, custom-quiz:focus, custom-tooltip:focus, custom-rating:focus {
  box-shadow: var(--shadow-lg);
  background-color: var(--color-bg-valence-neutral);
  transform: translateY(-2px);
}

custom-rhythm {
  --color-accent-main: #ff5722;
  --color-accent-alt: #ff9800;
  --color-bg-valence-neutral-hover: #ff572233;
  --color-bg-step-hover: #ff57221a;
  --color-bg-step-active: #ff572233;
  --color-positive: #4caf50;
  --color-bg-valence-neutral: #ffffff0d;
  --shadow-hover: 0 8px 16px #0003;
  --interactive-hover-scale: 1.05;
  --cursor: pointer;
  --font-size-sm: .95rem;
}

custom-quiz {
  border-left: var(--border-width-medium) solid var(--color-accent-main);
  position: relative;
}

custom-tooltip {
  display: inline-block;
  position: relative;
}

custom-tooltip .tooltip {
  background-color: var(--color-text-neutral);
  color: var(--color-text-content);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-tooltip);
  white-space: nowrap;
  box-shadow: var(--shadow-sm);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition-speed-fast) var(--transition-function), visibility var(--transition-speed-fast) var(--transition-function);
  z-index: var(--z-index-tooltip);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

custom-tooltip:after {
  content: "";
  border-style: solid;
  border-width: 5px;
  border-color: var(--color-text-neutral) transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

custom-tooltip:hover .tooltip, custom-tooltip:focus-within .tooltip {
  opacity: 1;
  visibility: visible;
}

custom-rating {
  gap: var(--spacing-xs);
  display: flex;
}

custom-rating .star {
  color: var(--color-text-alt);
  cursor: pointer;
  transition: color var(--transition-speed-fast) var(--transition-function), transform var(--transition-speed-fast) var(--transition-function);
  font-size: 1.5rem;
}

custom-rating.readonly .star {
  cursor: not-allowed;
  pointer-events: none;
}

custom-reflection {
  border-bottom: var(--border-width-thin) dashed var(--color-neutral);
  padding-bottom: var(--spacing-sm);
  cursor: pointer;
  transition: background-color var(--transition-speed-fast) var(--transition-function);
}

custom-reflection:hover, custom-reflection:focus {
  background-color: var(--color-bg-valence-positive);
}

custom-mirror {
  border: var(--border-width-thin) dotted var(--color-accent-alt);
  padding: var(--spacing-sm);
  transition: transform var(--transition-speed-fast) var(--transition-function), box-shadow var(--transition-speed-fast) var(--transition-function);
}

custom-mirror:hover, custom-mirror:focus {
  box-shadow: var(--shadow-hover);
  transform: rotate(3deg);
}

@keyframes blink {
  0%, 100% {
    opacity: .8;
  }

  50% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.01);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.emotion-valence-high {
  color: var(--color-positive);
}

.emotion-valence-neutral {
  color: var(--color-neutral);
}

.emotion-valence-low {
  color: var(--color-negative);
}

.emotion-arousal-high {
  animation: pulse var(--animation-duration-medium) infinite;
}

.emotion-arousal-medium {
  animation: pulse var(--animation-duration-long) infinite;
}

.emotion-arousal-low {
  animation: none;
}

.emotion-thoughtful {
  text-decoration: underline dotted;
}

.emotion-curiosity {
  transition: transform var(--transition-speed-medium) var(--transition-ease);
  display: inline-block;
}

.emotion-curiosity:hover, .emotion-curiosity:focus {
  transform: rotate(5deg);
}

.emotion-suspense {
  box-shadow: 0 0 10px var(--color-shadow-dark);
}

.emotion-intrigue {
  background-color: var(--color-bg-intrigue);
}

.emotion-thoughtful.custom-reflection {
  border-bottom: var(--border-width-thin) dashed var(--color-neutral);
  padding-bottom: var(--spacing-sm);
}

.emotion-intrigue.custom-reflection:hover, .emotion-intrigue.custom-reflection:focus {
  background-color: var(--color-bg-valence-positive);
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-bg-main: #121212;
    --color-bg-content: #1e1e1e;
    --color-text-main: #e0e0e0;
    --color-text-alt: #bbb;
    --color-text-content: #fff;
    --color-accent-main: #bb86fc;
    --color-accent-alt: #03dac6;
    --color-accent-gradient: linear-gradient(45deg, #bb86fc, #03dac6);
    --color-primary: #03dac6;
    --color-bg-valence-neutral: #3d405b33;
    --color-bg-intrigue: #2a9d8f1a;
    --color-bg-whisper: #2828280d;
  }

  body {
    background-color: var(--color-bg-main);
    color: var(--color-text-main);
  }

  main.chapter {
    background-color: var(--color-bg-content);
  }

  a {
    color: var(--color-primary);
  }

  h1:before {
    background: var(--color-accent-alt);
  }

  h2 {
    color: var(--color-accent-main);
  }

  blockquote {
    border-left: var(--border-width-medium) solid var(--color-accent-main);
    background-color: var(--color-bg-emphasis);
  }

  blockquote:hover, blockquote:focus-within {
    background-color: var(--color-bg-valence-neutral);
    border-color: var(--color-accent-alt);
  }

  .section.rhythm, .section.dialogue, .section.whisper {
    background-color: #ffffff0d;
  }

  .section.rhythm:hover, .section.dialogue:hover, .section.whisper:hover {
    background-color: var(--color-bg-valence-neutral);
  }

  custom-tooltip {
    background-color: var(--color-text-neutral);
  }

  custom-tooltip .tooltip {
    background-color: var(--color-text-neutral);
    color: var(--color-text-content);
  }

  custom-tooltip:after {
    border-color: var(--color-text-neutral) transparent transparent transparent;
  }
}

@media (width <= 1024px) {
  :root {
    --font-size-body: clamp(1.1rem, 2.5vw, 1.4rem);
    --font-size-h1: clamp(1.8rem, 4vw, 2.4rem);
    --font-size-h2: clamp(1.4rem, 3.5vw, 1.8rem);
    --font-size-h3: clamp(1.2rem, 3vw, 1.6rem);
  }

  main.chapter {
    padding: var(--spacing-md);
  }

  .section.rhythm {
    font-size: calc(var(--font-size-body) * .9);
  }
}

@media (width <= 480px) {
  :root {
    --font-size-body: clamp(1rem, 3vw, 1.2rem);
    --font-size-h1: clamp(1.5rem, 5vw, 2rem);
    --font-size-h2: clamp(1.2rem, 4vw, 1.5rem);
    --font-size-h3: clamp(1rem, 3.5vw, 1.3rem);
  }

  body {
    padding: var(--spacing-lg) var(--spacing-sm);
    font-size: 1.2rem;
  }

  main.chapter {
    padding: var(--spacing-md);
  }

  .section.rhythm {
    font-size: calc(var(--font-size-body) * .85);
  }

  h1:before {
    width: 30px;
    height: 2px;
  }

  custom-tooltip .tooltip {
    font-size: var(--font-size-tooltip);
  }

  .gradient-text {
    font-size: inherit;
  }
}

@media (width <= 768px) {
  custom-tooltip .tooltip {
    font-size: .8rem;
  }

  custom-tooltip:after {
    border-width: 4px;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: var(--spacing-lg);
}

th, td {
  padding: var(--spacing-sm);
  border: var(--border-width-thin) solid var(--color-neutral);
  text-align: left;
}

th {
  background-color: var(--color-accent-alt);
  color: var(--color-text-content);
}

tr:nth-child(2n) {
  background-color: #ffffff0d;
}

tr:hover {
  background-color: var(--color-bg-valence-neutral);
}

@media (width <= 600px) {
  table, thead, tbody, th, td, tr {
    display: block;
  }

  thead tr {
    display: none;
  }

  tr {
    margin-bottom: var(--spacing-md);
  }

  td {
    padding-left: var(--spacing-md);
    text-align: right;
    position: relative;
  }

  td:before {
    content: attr(data-label);
    width: 50%;
    padding-left: var(--spacing-xs);
    text-align: left;
    font-weight: bold;
    position: absolute;
    left: 0;
  }
}

.video-responsive {
  height: 0;
  padding-top: 25px;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.video-responsive iframe, .video-responsive object, .video-responsive embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

form {
  gap: var(--spacing-md);
  width: 100%;
  max-width: 500px;
  margin: var(--spacing-lg) 0;
  flex-direction: column;
  display: flex;
}

input, textarea, select, button {
  padding: var(--spacing-sm);
  border: var(--border-width-thin) solid var(--color-neutral);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-body);
  transition: border-color var(--transition-speed-fast) var(--transition-function), box-shadow var(--transition-speed-fast) var(--transition-function);
}

input:focus, textarea:focus, select:focus {
  border-color: var(--color-accent-alt);
  box-shadow: 0 0 5px var(--color-accent-alt);
  outline: none;
}

button {
  background: var(--color-accent-gradient);
  color: var(--color-text-content);
  cursor: pointer;
}

button:hover, button:focus {
  background-color: var(--color-accent-alt);
  box-shadow: var(--shadow-hover);
}

button:disabled {
  cursor: not-allowed;
  opacity: .6;
}

.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: var(--color-text-neutral);
  width: 200px;
  color: var(--color-text-content);
  text-align: center;
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-xs) var(--spacing-sm);
  z-index: var(--z-index-tooltip);
  opacity: 0;
  transition: opacity var(--transition-speed-fast) var(--transition-function);
  margin-left: -100px;
  position: absolute;
  bottom: 125%;
  left: 50%;
}

.tooltip:hover .tooltiptext, .tooltip:focus .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext:after {
  content: "";
  border-style: solid;
  border-width: 5px;
  border-color: var(--color-text-neutral) transparent transparent transparent;
  margin-left: -5px;
  position: absolute;
  top: 100%;
  left: 50%;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"5\"><polygon points=\"0,0 10,0 5,5\" fill=\"%23c9ada7\"/></svg>");
  background-repeat: no-repeat;
  background-position: right var(--spacing-sm) center;
  background-size: 10px 5px;
  padding-right: 2rem;
}

select:hover, select:focus {
  border-color: var(--color-accent-alt);
  box-shadow: 0 0 5px var(--color-accent-alt);
}

input[type="checkbox"], input[type="radio"] {
  accent-color: var(--color-accent-alt);
}

input[type="range"] {
  background: var(--color-neutral);
  border-radius: var(--border-radius-sm);
  width: 100%;
  height: 4px;
  transition: background var(--transition-speed-fast) var(--transition-function);
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  background: var(--color-accent-alt);
  cursor: pointer;
  width: 16px;
  height: 16px;
  transition: background var(--transition-speed-fast) var(--transition-function), transform var(--transition-speed-fast) var(--transition-function);
  border-radius: 50%;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: var(--color-accent-main);
  transform: scale(1.2);
}

input[type="range"]::-webkit-slider-thumb:focus {
  background: var(--color-accent-main);
  transform: scale(1.2);
}

.modal-overlay {
  width: 100%;
  height: 100%;
  z-index: var(--z-index-overlay);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition-speed-fast) var(--transition-function), visibility var(--transition-speed-fast) var(--transition-function);
  background-color: #000000b3;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-overlay.active {
  opacity: 1;
  visibility: visible;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.mt-1 {
  margin-top: var(--spacing-xs);
}

.mt-2 {
  margin-top: var(--spacing-sm);
}

.mt-3 {
  margin-top: var(--spacing-md);
}

.mb-1 {
  margin-bottom: var(--spacing-xs);
}

.mb-2 {
  margin-bottom: var(--spacing-sm);
}

.mb-3 {
  margin-bottom: var(--spacing-md);
}

.pt-1 {
  padding-top: var(--spacing-xs);
}

.pt-2 {
  padding-top: var(--spacing-sm);
}

.pt-3 {
  padding-top: var(--spacing-md);
}

.pb-1 {
  padding-bottom: var(--spacing-xs);
}

.pb-2 {
  padding-bottom: var(--spacing-sm);
}

.pb-3 {
  padding-bottom: var(--spacing-md);
}

.px-1 {
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
}

.px-2 {
  padding-left: var(--spacing-sm);
  padding-right: var(--spacing-sm);
}

.py-1 {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
}

.py-2 {
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
}

.justify-between {
  justify-content: space-between;
}

.hidden {
  display: none !important;
}

.visible {
  display: block !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.bg-primary {
  background-color: var(--color-primary);
}

.bg-accent {
  background-color: var(--color-accent-main);
}

.text-primary {
  color: var(--color-primary);
}

.text-accent {
  color: var(--color-accent-main);
}

.border-primary {
  border-color: var(--color-primary);
}

.border-accent {
  border-color: var(--color-accent-main);
}

.z-tooltip {
  z-index: var(--z-index-tooltip);
}

.z-overlay {
  z-index: var(--z-index-overlay);
}

.z-modal {
  z-index: var(--z-index-modal);
}

@media (width <= 1200px) {
  :root {
    --max-width-content: calc(min(85vw, 100%));
  }
}

@media (width <= 768px) {
  body {
    padding: var(--spacing-lg) var(--spacing-md);
    font-size: 1.4rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  main.chapter {
    padding: var(--spacing-lg);
    background-attachment: scroll;
  }

  nav.chapter-navigation, .section-navigation {
    gap: var(--spacing-sm);
    flex-direction: column;
  }

  nav.chapter-navigation button, .section-navigation button {
    width: 100%;
  }

  .dialogue {
    padding: var(--spacing-xs);
    border-left: 3px solid var(--color-primary);
  }

  .custom-reflection {
    padding: var(--spacing-sm);
  }

  .section.rhythm {
    padding: var(--spacing-sm);
    font-size: calc(var(--font-size-body) * .95);
  }

  .section.whisper {
    font-size: calc(var(--font-size-body) * .9);
  }

  .section.dialogue {
    padding: var(--spacing-sm);
  }
}

@media (prefers-reduced-motion: reduce) {
  *, :before, :after {
    transition: none !important;
    animation: none !important;
  }

  .emotion-curiosity:hover, .emotion-curiosity:focus, .custom-puzzle:hover, .custom-reflection:hover, .custom-mirror:hover, .custom-quiz:hover, .custom-tooltip:hover, .custom-rating:hover {
    box-shadow: none !important;
    background-color: var(--color-bg-content) !important;
    transform: none !important;
  }

  figure img:hover, figure img:focus {
    box-shadow: var(--shadow-sm) !important;
    transform: none !important;
  }

  .section.rhythm, .section.whisper, .section.dialogue {
    transition: none !important;
  }
}

.gradient-text {
  background: linear-gradient(90deg, var(--color-accent-main), var(--color-accent-alt));
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-clip: text;
}

.glassmorphism {
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  background: #ffffff1a;
  border: 1px solid #fff3;
}

.neon-glow {
  color: var(--color-accent-alt);
  text-shadow: 0 0 5px var(--color-accent-alt), 0 0 10px var(--color-accent-alt), 0 0 20px var(--color-accent-alt), 0 0 40px var(--color-accent-alt), 0 0 80px var(--color-accent-alt);
  transition: text-shadow var(--transition-speed-fast) var(--transition-function);
}

.neon-glow:hover, .neon-glow:focus {
  text-shadow: 0 0 10px var(--color-accent-alt), 0 0 20px var(--color-accent-alt), 0 0 40px var(--color-accent-alt), 0 0 80px var(--color-accent-alt), 0 0 160px var(--color-accent-alt);
}

:root {
  --font-size-responsive-h1: clamp(2rem, 5vw, 3rem);
  --font-size-responsive-h2: clamp(1.5rem, 4vw, 2.5rem);
  --font-size-responsive-h3: clamp(1.2rem, 3vw, 2rem);
}

h1 {
  font-size: var(--font-size-responsive-h1);
}

h2 {
  font-size: var(--font-size-responsive-h2);
}

h3 {
  font-size: var(--font-size-responsive-h3);
}

button, .custom-puzzle, .custom-reflection, .custom-mirror, .custom-quiz, .custom-tooltip, .custom-rating {
  position: relative;
  overflow: hidden;
}

button:before, .custom-puzzle:before, .custom-reflection:before, .custom-mirror:before, .custom-quiz:before, .custom-tooltip:before, .custom-rating:before {
  content: "";
  width: 100%;
  height: 100%;
  transition: all var(--transition-speed-fast) var(--transition-function);
  background: #fff3;
  position: absolute;
  top: 0;
  left: -100%;
  transform: skewX(-45deg);
}

button:hover:before, button:focus:before, .custom-puzzle:hover:before, .custom-puzzle:focus:before, .custom-reflection:hover:before, .custom-reflection:focus:before, .custom-mirror:hover:before, .custom-mirror:focus:before, .custom-quiz:hover:before, .custom-quiz:focus:before, .custom-tooltip:hover:before, .custom-tooltip:focus:before, .custom-rating:hover:before, .custom-rating:focus:before {
  background: #fff3;
  left: 100%;
}

button:after, .custom-puzzle:after, .custom-reflection:after, .custom-mirror:after, .custom-quiz:after, .custom-tooltip:after, .custom-rating:after {
  content: "";
  border-radius: var(--border-radius-md);
  width: 100%;
  height: 100%;
  transition: all var(--transition-speed-fast) var(--transition-function);
  position: absolute;
  top: 0;
  left: 0;
}

button:hover:after, button:focus:after, .custom-puzzle:hover:after, .custom-puzzle:focus:after, .custom-reflection:hover:after, .custom-reflection:focus:after, .custom-mirror:hover:after, .custom-mirror:focus:after, .custom-quiz:hover:after, .custom-quiz:focus:after, .custom-tooltip:hover:after, .custom-tooltip:focus:after, .custom-rating:hover:after, .custom-rating:focus:after {
  box-shadow: 0 0 10px var(--color-accent-alt), 0 0 20px var(--color-accent-alt);
}

custom-tooltip:after {
  border-color: var(--color-text-neutral) transparent transparent transparent;
}

@media (width <= 768px) {
  custom-tooltip .tooltip {
    font-size: .8rem;
  }

  custom-tooltip:after {
    border-width: 4px;
  }
}

figure img {
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
  transition: transform var(--transition-speed-fast) var(--transition-function), box-shadow var(--transition-speed-fast) var(--transition-function);
  cursor: pointer;
  object-fit: cover;
  max-height: 60vh;
}

figure img:hover, figure img:focus {
  transform: scale(var(--interactive-hover-scale)) rotate(2deg);
  box-shadow: var(--shadow-hover);
}

.grid-container {
  gap: var(--spacing-md);
  width: 100%;
  margin-bottom: var(--spacing-lg);
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  display: grid;
}

.grid-item {
  background-color: var(--color-bg-content);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
  transition: transform var(--transition-speed-fast) var(--transition-function), box-shadow var(--transition-speed-fast) var(--transition-function), background-color var(--transition-speed-fast) var(--transition-function);
}

.grid-item:hover, .grid-item:focus-within {
  box-shadow: var(--shadow-lg);
  background-color: var(--color-bg-valence-neutral);
  transform: translateY(-2px);
}

custom-modal {
  background-color: var(--color-bg-content);
  border-radius: var(--border-radius-lg);
  width: 90%;
  max-width: 600px;
  box-shadow: var(--shadow-lg);
  z-index: var(--z-index-modal);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition-speed-fast) var(--transition-function), transform var(--transition-speed-fast) var(--transition-function), visibility var(--transition-speed-fast) var(--transition-function);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.8);
}

custom-modal[isOpen="true"] {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%)scale(1);
}

custom-modal header {
  padding: var(--spacing-sm) var(--spacing-md);
  border-bottom: var(--border-width-thin) solid var(--color-accent-alt);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

custom-modal header h2 {
  font-size: var(--font-size-h2);
  color: var(--color-text-content);
}

custom-modal header button.close-button {
  color: var(--color-text-content);
  cursor: pointer;
  transition: color var(--transition-speed-fast) var(--transition-function);
  background: none;
  border: none;
  font-size: 1.5rem;
}

custom-modal header button.close-button:hover, custom-modal header button.close-button:focus {
  color: var(--color-accent-alt);
}

custom-modal .modal-content {
  padding: var(--spacing-md);
  max-height: 70vh;
  overflow-y: auto;
}

custom-modal .modal-footer {
  padding: var(--spacing-sm) var(--spacing-md);
  border-top: var(--border-width-thin) solid var(--color-accent-alt);
  justify-content: flex-end;
  display: flex;
}

custom-modal .modal-footer button {
  margin-left: var(--spacing-sm);
}

custom-rating .star {
  transition: color var(--transition-speed-fast) var(--transition-function), transform var(--transition-speed-fast) var(--transition-function);
}

custom-rating .star.filled {
  color: var(--color-positive);
}

custom-rating .star:hover, custom-rating .star:focus {
  color: var(--color-accent-alt);
  transform: scale(1.2);
}

.symbol {
  padding: var(--spacing-xs) var(--spacing-sm);
  margin: var(--spacing-xs);
  background-color: var(--color-bg-content);
  border: var(--border-width-thin) solid var(--color-accent-main);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: background-color var(--transition-speed-fast) var(--transition-function), transform var(--transition-speed-fast) var(--transition-function), box-shadow var(--transition-speed-fast) var(--transition-function);
  display: inline-block;
}

.symbol:hover, .symbol:focus {
  background-color: var(--color-bg-valence-neutral);
  transform: scale(var(--interactive-hover-scale));
  box-shadow: var(--shadow-hover);
}

.loader {
  border: var(--border-width-thin) solid #f3f3f3;
  border-top: var(--border-width-thin) solid var(--color-accent-alt);
  width: 40px;
  height: 40px;
  margin: var(--spacing-lg) auto;
  border-radius: 50%;
  animation: 1s linear infinite spin;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-nowrap {
  white-space: nowrap;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  background-color: #fff;
}

.bg-black {
  background-color: #000;
}

.border-none {
  border: none;
}

.border-top-none {
  border-top: none;
}

.border-bottom-none {
  border-bottom: none;
}

.border-radius-none {
  border-radius: 0;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.d-none {
  display: none !important;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, 1fr);
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.gap-1 {
  gap: var(--spacing-xs);
}

.gap-2 {
  gap: var(--spacing-sm);
}

.gap-3 {
  gap: var(--spacing-md);
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-static {
  position: static;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-scroll {
  overflow: scroll;
}

@media (prefers-color-scheme: dark) {
  custom-tooltip .tooltiptext {
    background-color: var(--color-bg-content);
    color: var(--color-text-content);
  }

  custom-tooltip:after {
    border-color: var(--color-bg-content) transparent transparent transparent;
  }

  .loader {
    border-top: var(--border-width-thin) solid var(--color-accent-alt);
  }

  .section.rhythm, .section.whisper, .section.dialogue {
    background-color: #ffffff0d;
  }

  .section.rhythm:hover, .section.dialogue:hover, .section.whisper:hover {
    background-color: var(--color-bg-valence-neutral);
  }
}
/*# sourceMappingURL=index.890b5cc4.css.map */
