/* === styles/global.css === */

/* --------------------
   1. CSS Variables
   - Organized for better clarity and scalability.
-------------------- */
:root {
    /* --------------------
       1.1 Font Families - Typography Optimization
    -------------------- */
    --font-family-primary: 'JetBrains Mono', monospace;
    --font-family-secondary: 'Arial', sans-serif;
    --font-family-emphasis: 'Cinzel', serif; /* Retained for special emphasis */

    /* --------------------
       1.2 Font Sizes - Enhanced Readability with Fluid Typography
    -------------------- */
    --font-size-body: clamp(1.2rem, 2vw, 1.6rem);
    --font-size-h1: clamp(2rem, 5vw, 2.8rem);
    --font-size-h2: clamp(1.6rem, 4vw, 2.2rem);
    --font-size-h3: clamp(1.3rem, 3vw, 1.9rem);
    --font-size-caption: clamp(0.8rem, 2vw, 0.9rem);
    --font-size-button: clamp(1rem, 2.5vw, 1.05rem);
    --font-size-tooltip: clamp(0.7rem, 1.5vw, 0.9rem);

    /* --------------------
       1.3 Line Heights - Enhanced Readability
    -------------------- */
    --line-height-main: 1.8;

    /* --------------------
       1.4 Colors - Enhanced Contrast and Warmth
    -------------------- */
    /* Base Colors */
    --color-bg-main: #1e1e1e;
    --color-bg-content: #2a2a2a;
    --color-text-main: #e0e0e0;
    --color-text-alt: #cccccc;
    --color-text-content: #ffffff;

    /* Emotion Valence Colors */
    --color-positive: #e07a5f;
    --color-negative: #d62828;
    --color-neutral: #3d405b;
    --color-bg-valence-positive: rgba(224, 122, 95, 0.2);
    --color-bg-valence-negative: rgba(214, 40, 40, 0.2);
    --color-bg-valence-neutral: rgba(61, 64, 91, 0.2);
    --feedback-color: var(--color-negative);

    /* Shadows and Highlights */
    --color-shadow-dark: rgba(0, 0, 0, 0.5);
    --color-bg-emphasis: rgba(0, 0, 0, 0.05);
    --color-bg-whisper: rgba(40, 40, 40, 0.05);
    --color-bg-intrigue: rgba(42, 157, 143, 0.1);
    --color-hover-bg: rgba(0, 131, 176, 0.2);

    /* Accent Colors */
    --color-accent-main: #f4f1de;
    --color-accent-alt: #c9ada7;
    --color-accent-gradient: linear-gradient(45deg, #f4f1de, #c9ada7);
    --color-primary: #3d405b;

    /* Text Colors for Emotions */
    --color-text-neutral: var(--color-neutral);
    --color-text-negative: #d62828;

    /* --------------------
       1.5 Transition Settings
    -------------------- */
    --transition-speed-fast: 0.2s;
    --transition-speed-medium: 0.3s;
    --transition-speed-slow: 0.5s;
    --transition-ease: ease;
    --transition-function: ease-in-out;

    /* --------------------
       1.6 Spacing - Refined for Better Layout
    -------------------- */
    --spacing-xs: 0.3rem;
    --spacing-sm: 0.6rem;
    --spacing-md: 1.2rem;
    --spacing-lg: 1.8rem;
    --spacing-xl: 2.4rem;

    /* --------------------
       1.7 Border Settings
    -------------------- */
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --border-radius-lg: 12px;
    --border-width-thin: 1px;
    --border-width-medium: 2px;

    /* --------------------
       1.8 Shadows - Refined for Clarity
    -------------------- */
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.3);
    --shadow-md: 0 3px 6px rgba(0, 0, 0, 0.4);
    --shadow-lg: 0 5px 15px rgba(0, 0, 0, 0.4);
    --shadow-hover: 0 6px 12px rgba(0, 0, 0, 0.3);

    /* --------------------
       1.9 Z-Index
    -------------------- */
    --z-index-tooltip: 10;
    --z-index-overlay: 500;
    --z-index-modal: 1000;
    --z-index-dropdown: 200;

    /* --------------------
       1.10 Animation Durations
    -------------------- */
    --animation-duration-short: 0.3s;
    --animation-duration-medium: 0.6s;
    --animation-duration-long: 1s;

    /* --------------------
       1.11 Background Colors for Moods
    -------------------- */
    --bg-color-main: hsl(39, 77%, 83%);
    --accent-color-main: teal;

    --max-width-content: calc(min(70vw, 100%));

    /* --------------------
       1.12 Interactive States
    -------------------- */
    --interactive-hover-scale: 1.05;
    --interactive-active-scale: 0.98;
    --interactive-focus-outline: 2px dashed var(--color-accent-alt);

    /* --------------------
       1.13 Dark Mode Colors (Defaults for Light Mode)
    -------------------- */
    --color-bg-main-light: #ffffff;
    --color-bg-content-light: #f9f9f9;
    --color-text-main-light: #1e1e1e;
    --color-text-alt-light: #555555;
    --color-text-content-light: #000000;
}

/* --------------------
   2. Global Resets and Base Styles
-------------------- */

/* Enhanced Focus States */
:focus {
    outline: var(--interactive-focus-outline);
    outline-offset: 4px;
}

*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
    font-family: var(--font-family-primary);
}

body {
    min-height: 100vh;
    padding: var(--spacing-xl) var(--spacing-lg);
    display: flex;
    flex-direction: column;
    background-color: var(--color-bg-main);
    font-size: var(--font-size-body);
    line-height: var(--line-height-main);
    color: var(--color-text-main);
    transition: background-color var(--transition-speed-medium) var(--transition-function);
}

/* --------------------
   3. Typography
-------------------- */

a {
    color: var(--color-primary);
    text-decoration: none;
    transition: color var(--transition-speed-fast) var(--transition-function);
    position: relative;
}

a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--color-accent-alt);
    transition: width var(--transition-speed-fast) var(--transition-function);
    position: absolute;
    bottom: -2px;
    left: 0;
}

a:hover::after,
a:focus::after {
    width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-primary);
    color: var(--color-text-main);
    line-height: 1.2;
    margin-bottom: var(--spacing-sm);
}

h1 {
    font-size: var(--font-size-h1);
    color: var(--color-accent-alt);
    text-align: center;
    position: relative;
}

h1::before {
    content: '';
    position: absolute;
    width: 50px;
    height: 3px;
    background: var(--color-accent-alt);
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

h2 {
    font-size: var(--font-size-h2);
    color: var(--color-accent-main);
}

h3 {
    font-size: var(--font-size-h3);
}

p {
    margin-bottom: var(--spacing-md);
    line-height: 1.8;
}

blockquote {
    border-left: var(--border-width-medium) solid var(--color-accent-main);
    padding-left: var(--spacing-md);
    color: var(--color-text-alt);
    font-style: italic;
    margin: var(--spacing-lg) 0;
    background-color: var(--color-bg-emphasis);
    border-radius: var(--border-radius-sm);
    position: relative;
    transition: background-color var(--transition-speed-fast) var(--transition-function),
    border-color var(--transition-speed-fast) var(--transition-function);
}

blockquote::before {
    content: open-quote;
    font-size: 2rem;
    position: absolute;
    top: -10px;
    left: -10px;
    color: var(--color-accent-main);
}

/* --------------------
   4. Layout and Containers
-------------------- */

main.chapter {
    max-width: var(--max-width-content);
    width: 100%;
    margin: var(--spacing-xl) auto;
    padding: var(--spacing-lg);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--color-bg-content);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-md);
    animation: fadeIn 0.4s ease-in-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    transition: box-shadow var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function);
}

main.chapter:hover {
    box-shadow: var(--shadow-lg);
    transform: translateY(-2px);
}

figure {
    margin: var(--spacing-lg) 0;
    text-align: center;
    max-width: calc(min(70vw, 80%));
}

figure img {
    max-width: 100%;
    height: auto;
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
    transition: transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function);
    cursor: pointer;
    max-height: 60vh;
    object-fit: cover;
}

figure img:hover,
figure img:focus {
    transform: scale(var(--interactive-hover-scale)) rotate(2deg);
    box-shadow: var(--shadow-hover);
}

figcaption {
    font-size: var(--font-size-caption);
    color: var(--color-text-alt);
    margin-top: var(--spacing-sm);
    text-align: center;
}

/* --------------------
   5. Navigation
-------------------- */

nav.chapter-navigation,
.section-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-lg);
    width: 100%;
    gap: var(--spacing-sm);
}

nav.chapter-navigation button,
.section-navigation button,
.additional-links a,
.primary-action,
#lore-button {
    background: var(--color-accent-gradient);
    color: var(--color-text-content);
    padding: var(--spacing-sm) var(--spacing-md);
    border: none;
    border-radius: var(--border-radius-md);
    cursor: pointer;
    font-size: var(--font-size-button);
    transition: background-color var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function);
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

nav.chapter-navigation button:hover,
nav.chapter-navigation button:focus,
.section-navigation button:hover,
.section-navigation button:focus,
.additional-links a:hover,
.additional-links a:focus,
.primary-action:hover,
.primary-action:focus,
#lore-button:hover,
#lore-button:focus {
    background-color: var(--color-accent-alt);
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
}

nav.chapter-navigation button:active,
.section-navigation button:active,
.primary-action:active {
    transform: translateY(1px);
    box-shadow: var(--shadow-sm);
}

.additional-links {
    margin-top: var(--spacing-md);
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-sm);
}

.additional-links a {
    display: inline-block;
    padding: var(--spacing-sm) var(--spacing-md);
    background: var(--color-accent-main);
    border-radius: var(--border-radius-md);
    transition: background-color var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function);
    text-decoration: none;
    color: var(--color-text-content);
}

.additional-links a:hover,
.additional-links a:focus {
    background-color: var(--color-accent-alt);
    transform: scale(var(--interactive-hover-scale));
    box-shadow: var(--shadow-hover);
}

.primary-action {
    display: block;
    width: 100%;
    margin-top: var(--spacing-md);
    background: var(--color-accent-gradient);
    text-align: center;
    transition: background-color var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function);
}

#lore-button {
    background: var(--color-accent-gradient);
}

#lore-button:hover,
#lore-button:focus {
    background-color: var(--color-accent-alt);
}

/* --------------------
   6. Collections and Lists
-------------------- */

#lore-collector {
    display: none;
    list-style: none;
    padding: 0;
    margin-bottom: var(--spacing-md);
}

#lore-collector li {
    margin-bottom: var(--spacing-xs);
    padding: var(--spacing-xs);
    background-color: var(--color-hover-bg);
    border-radius: var(--border-radius-sm);
    transition: background-color var(--transition-speed-fast) var(--transition-function);
}

#lore-collector li:hover {
    background-color: var(--color-bg-valence-neutral);
}

/* --------------------
   7. Custom Elements Styles
-------------------- */

/* Common Styles for Custom Elements */
custom-puzzle,
custom-reflection,
custom-mirror,
custom-quiz,
custom-tooltip,
custom-rating {
    display: block;
    padding: var(--spacing-lg);
    margin-bottom: var(--spacing-lg);
    background-color: var(--color-bg-content);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    transition: transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function),
    background-color var(--transition-speed-fast) var(--transition-function);
}

custom-puzzle:hover,
custom-reflection:hover,
custom-mirror:hover,
custom-quiz:hover,
custom-tooltip:hover,
custom-rating:hover,
custom-puzzle:focus,
custom-reflection:focus,
custom-mirror:focus,
custom-quiz:focus,
custom-tooltip:focus,
custom-rating:focus {
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
    background-color: var(--color-bg-valence-neutral);
}

/* Specific Custom Element Enhancements */

/* 7.1 CustomRhythm Enhancements */
custom-rhythm {
    --color-accent-main: #ff5722;
    --color-accent-alt: #ff9800;
    --color-bg-valence-neutral-hover: rgba(255, 87, 34, 0.2);
    --color-bg-step-hover: rgba(255, 87, 34, 0.1);
    --color-bg-step-active: rgba(255, 87, 34, 0.2);
    --color-positive: #4caf50;
    --color-bg-valence-neutral: rgba(255, 255, 255, 0.05);
    --shadow-hover: 0 8px 16px rgba(0, 0, 0, 0.2);
    --interactive-hover-scale: 1.05;
    --cursor: pointer;
    --font-size-sm: 0.95rem;
}

/* 7.2 CustomQuiz Enhancements */
custom-quiz {
    border-left: var(--border-width-medium) solid var(--color-accent-main);
    position: relative;
}

/* 7.3 CustomTooltip Enhancements */
custom-tooltip {
    position: relative;
    display: inline-block;
}

custom-tooltip .tooltip {
    position: absolute;
    background-color: var(--color-text-neutral);
    color: var(--color-text-content);
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-tooltip);
    white-space: nowrap;
    box-shadow: var(--shadow-sm);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition-speed-fast) var(--transition-function),
    visibility var(--transition-speed-fast) var(--transition-function);
    z-index: var(--z-index-tooltip);
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}

custom-tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: var(--color-text-neutral) transparent transparent transparent;
}

custom-tooltip:hover .tooltip,
custom-tooltip:focus-within .tooltip {
    opacity: 1;
    visibility: visible;
}

/* 7.4 CustomRating Enhancements */
custom-rating {
    display: flex;
    gap: var(--spacing-xs);
}

custom-rating .star {
    font-size: 1.5rem;
    color: var(--color-text-alt);
    cursor: pointer;
    transition: color var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function);
}

custom-rating .star.filled {
    color: var(--color-positive);
}

custom-rating .star:hover,
custom-rating .star:focus {
    color: var(--color-accent-alt);
    transform: scale(1.2);
}

custom-rating.readonly .star {
    cursor: not-allowed;
    pointer-events: none;
}

/* 7.5 CustomReflection Enhancements */
custom-reflection {
    border-bottom: var(--border-width-thin) dashed var(--color-neutral);
    padding-bottom: var(--spacing-sm);
    cursor: pointer;
    transition: background-color var(--transition-speed-fast) var(--transition-function);
}

custom-reflection:hover,
custom-reflection:focus {
    background-color: var(--color-bg-valence-positive);
}

/* 7.6 CustomMirror Enhancements */
custom-mirror {
    border: var(--border-width-thin) dotted var(--color-accent-alt);
    padding: var(--spacing-sm);
    transition: transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function);
}

custom-mirror:hover,
custom-mirror:focus {
    transform: rotate(3deg);
    box-shadow: var(--shadow-hover);
}

/* --------------------
   8. Animations
-------------------- */

@keyframes blink {
    0%, 100% {
        opacity: 0.8;
    }
    50% {
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.01);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* --------------------
   9. Emotion-Based Styles (Extended)
-------------------- */

.emotion-valence-high {
    color: var(--color-positive);
}

.emotion-valence-neutral {
    color: var(--color-neutral);
}

.emotion-valence-low {
    color: var(--color-negative);
}

.emotion-arousal-high {
    animation: pulse var(--animation-duration-medium) infinite;
}

.emotion-arousal-medium {
    animation: pulse var(--animation-duration-long) infinite;
}

.emotion-arousal-low {
    animation: none;
}

.emotion-thoughtful {
    text-decoration: underline dotted;
}

.emotion-curiosity {
    display: inline-block;
    transition: transform var(--transition-speed-medium) var(--transition-ease);
}

.emotion-curiosity:hover,
.emotion-curiosity:focus {
    transform: rotate(5deg);
}

.emotion-suspense {
    box-shadow: 0 0 10px var(--color-shadow-dark);
}

.emotion-intrigue {
    background-color: var(--color-bg-intrigue);
}

.emotion-thoughtful.custom-reflection {
    border-bottom: var(--border-width-thin) dashed var(--color-neutral);
    padding-bottom: var(--spacing-sm);
}

.emotion-intrigue.custom-reflection:hover,
.emotion-intrigue.custom-reflection:focus {
    background-color: var(--color-bg-valence-positive);
    cursor: pointer;
}

/* --------------------
   10. Enhanced Dark Mode Support
-------------------- */

@media (prefers-color-scheme: dark) {
    :root {
        --color-bg-main: #121212;
        --color-bg-content: #1e1e1e;
        --color-text-main: #e0e0e0;
        --color-text-alt: #bbbbbb;
        --color-text-content: #ffffff;
        --color-accent-main: #bb86fc;
        --color-accent-alt: #03dac6;
        --color-accent-gradient: linear-gradient(45deg, #bb86fc, #03dac6);
        --color-primary: #03dac6;
        --color-bg-valence-neutral: rgba(61, 64, 91, 0.2);
        --color-bg-intrigue: rgba(42, 157, 143, 0.1);
        --color-bg-whisper: rgba(40, 40, 40, 0.05);
    }

    body {
        background-color: var(--color-bg-main);
        color: var(--color-text-main);
    }

    main.chapter {
        background-color: var(--color-bg-content);
    }

    a {
        color: var(--color-primary);
    }

    h1::before {
        background: var(--color-accent-alt);
    }

    h2 {
        color: var(--color-accent-main);
    }

    blockquote {
        border-left: var(--border-width-medium) solid var(--color-accent-main);
        background-color: var(--color-bg-emphasis);
    }

    blockquote:hover,
    blockquote:focus-within {
        background-color: var(--color-bg-valence-neutral);
        border-color: var(--color-accent-alt);
    }

    .section.rhythm,
    .section.dialogue,
    .section.whisper {
        background-color: rgba(255, 255, 255, 0.05);
    }

    .section.rhythm:hover,
    .section.dialogue:hover,
    .section.whisper:hover {
        background-color: var(--color-bg-valence-neutral);
    }

    custom-tooltip {
        background-color: var(--color-text-neutral);
    }

    custom-tooltip .tooltip {
        background-color: var(--color-text-neutral);
        color: var(--color-text-content);
    }

    custom-tooltip::after {
        border-color: var(--color-text-neutral) transparent transparent transparent;
    }
}

/* --------------------
   11. Additional Enhancements for Depth
-------------------- */

/* 11.1 Gradient Text for Emphasis */
.gradient-text {
    background: linear-gradient(90deg, var(--color-accent-main), var(--color-accent-alt));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

/* 11.2 Glassmorphism Effect */
.glassmorphism {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: var(--border-radius-md);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: var(--shadow-md);
}

/* 11.3 Neon Glow Effect */
.neon-glow {
    color: var(--color-accent-alt);
    text-shadow:
            0 0 5px var(--color-accent-alt),
            0 0 10px var(--color-accent-alt),
            0 0 20px var(--color-accent-alt),
            0 0 40px var(--color-accent-alt),
            0 0 80px var(--color-accent-alt);
    transition: text-shadow var(--transition-speed-fast) var(--transition-function);
}

.neon-glow:hover,
.neon-glow:focus {
    text-shadow:
            0 0 10px var(--color-accent-alt),
            0 0 20px var(--color-accent-alt),
            0 0 40px var(--color-accent-alt),
            0 0 80px var(--color-accent-alt),
            0 0 160px var(--color-accent-alt);
}

/* 11.4 Responsive Typography */
@media (max-width: 1024px) {
    :root {
        --font-size-body: clamp(1.1rem, 2.5vw, 1.4rem);
        --font-size-h1: clamp(1.8rem, 4vw, 2.4rem);
        --font-size-h2: clamp(1.4rem, 3.5vw, 1.8rem);
        --font-size-h3: clamp(1.2rem, 3vw, 1.6rem);
    }

    main.chapter {
        padding: var(--spacing-md);
    }

    .section.rhythm {
        font-size: calc(var(--font-size-body) * 0.9);
    }
}

@media (max-width: 480px) {
    :root {
        --font-size-body: clamp(1rem, 3vw, 1.2rem);
        --font-size-h1: clamp(1.5rem, 5vw, 2rem);
        --font-size-h2: clamp(1.2rem, 4vw, 1.5rem);
        --font-size-h3: clamp(1rem, 3.5vw, 1.3rem);
    }

    body {
        padding: var(--spacing-lg) var(--spacing-sm);
        font-size: 1.2rem;
    }

    main.chapter {
        padding: var(--spacing-md);
    }

    .section.rhythm {
        font-size: calc(var(--font-size-body) * 0.85);
    }

    h1::before {
        width: 30px;
        height: 2px;
    }

    custom-tooltip .tooltip {
        font-size: var(--font-size-tooltip);
    }

    .gradient-text {
        font-size: inherit;
    }
}

/* 11.5 Enhanced Button States */
button,
.custom-puzzle,
.custom-reflection,
.custom-mirror,
.custom-quiz,
.custom-tooltip,
.custom-rating {
    position: relative;
    overflow: hidden;
}

button::before,
.custom-puzzle::before,
.custom-reflection::before,
.custom-mirror::before,
.custom-quiz::before,
.custom-tooltip::before,
.custom-rating::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    background: rgba(255, 255, 255, 0.2);
    transition: all var(--transition-speed-fast) var(--transition-function);
    transform: skewX(-45deg);
}

button:hover::before,
button:focus::before,
.custom-puzzle:hover::before,
.custom-puzzle:focus::before,
.custom-reflection:hover::before,
.custom-reflection:focus::before,
.custom-mirror:hover::before,
.custom-mirror:focus::before,
.custom-quiz:hover::before,
.custom-quiz:focus::before,
.custom-tooltip:hover::before,
.custom-tooltip:focus::before,
.custom-rating:hover::before,
.custom-rating:focus::before {
    left: 100%;
    background: rgba(255, 255, 255, 0.2);
}

button::after,
.custom-puzzle::after,
.custom-reflection::after,
.custom-mirror::after,
.custom-quiz::after,
.custom-tooltip::after,
.custom-rating::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: var(--border-radius-md);
    transition: all var(--transition-speed-fast) var(--transition-function);
}

button:hover::after,
button:focus::after,
.custom-puzzle:hover::after,
.custom-puzzle:focus::after,
.custom-reflection:hover::after,
.custom-reflection:focus::after,
.custom-mirror:hover::after,
.custom-mirror:focus::after,
.custom-quiz:hover::after,
.custom-quiz:focus::after,
.custom-tooltip:hover::after,
.custom-tooltip:focus::after,
.custom-rating:hover::after,
.custom-rating:focus::after {
    box-shadow: 0 0 10px var(--color-accent-alt), 0 0 20px var(--color-accent-alt);
}

/* 11.6 Tooltip Arrow Enhancements */
custom-tooltip::after {
    border-color: var(--color-text-neutral) transparent transparent transparent;
}

@media (max-width: 768px) {
    custom-tooltip .tooltip {
        font-size: 0.8rem;
    }

    custom-tooltip::after {
        border-width: 4px;
    }
}

/* 11.7 Enhanced Responsive Images */
figure img {
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
    transition: transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function);
    cursor: pointer;
    max-height: 60vh;
    object-fit: cover;
}

figure img:hover,
figure img:focus {
    transform: scale(var(--interactive-hover-scale)) rotate(2deg);
    box-shadow: var(--shadow-hover);
}

/* 11.8 Responsive Grid Layout for Content */
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: var(--spacing-md);
    width: 100%;
    margin-bottom: var(--spacing-lg);
}

.grid-item {
    background-color: var(--color-bg-content);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
    transition: transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function),
    background-color var(--transition-speed-fast) var(--transition-function);
}

.grid-item:hover,
.grid-item:focus-within {
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
    background-color: var(--color-bg-valence-neutral);
}

/* 11.9 Enhanced Modal Styles */
custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    width: 90%;
    max-width: 600px;
    background-color: var(--color-bg-content);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-lg);
    z-index: var(--z-index-modal);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function),
    visibility var(--transition-speed-fast) var(--transition-function);
}

custom-modal[isOpen="true"] {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
}

custom-modal header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-sm) var(--spacing-md);
    border-bottom: var(--border-width-thin) solid var(--color-accent-alt);
}

custom-modal header h2 {
    font-size: var(--font-size-h2);
    color: var(--color-text-content);
}

custom-modal header button.close-button {
    background: none;
    border: none;
    color: var(--color-text-content);
    font-size: 1.5rem;
    cursor: pointer;
    transition: color var(--transition-speed-fast) var(--transition-function);
}

custom-modal header button.close-button:hover,
custom-modal header button.close-button:focus {
    color: var(--color-accent-alt);
}

custom-modal .modal-content {
    padding: var(--spacing-md);
    max-height: 70vh;
    overflow-y: auto;
}

custom-modal .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: var(--spacing-sm) var(--spacing-md);
    border-top: var(--border-width-thin) solid var(--color-accent-alt);
}

custom-modal .modal-footer button {
    margin-left: var(--spacing-sm);
}

/* 11.10 Enhanced Rating Stars */
custom-rating .star {
    transition: color var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function);
}

custom-rating .star.filled {
    color: var(--color-positive);
}

custom-rating .star:hover,
custom-rating .star:focus {
    color: var(--color-accent-alt);
    transform: scale(1.2);
}

/* 11.11 Interactive Symbol Styles */
.symbol {
    display: inline-block;
    padding: var(--spacing-xs) var(--spacing-sm);
    margin: var(--spacing-xs);
    background-color: var(--color-bg-content);
    border: var(--border-width-thin) solid var(--color-accent-main);
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    transition: background-color var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function);
}

.symbol:hover,
.symbol:focus {
    background-color: var(--color-bg-valence-neutral);
    transform: scale(var(--interactive-hover-scale));
    box-shadow: var(--shadow-hover);
}

/* 11.12 Loader Styles */
.loader {
    border: var(--border-width-thin) solid #f3f3f3;
    border-top: var(--border-width-thin) solid var(--color-accent-alt);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: var(--spacing-lg) auto;
}

/* --------------------
   12. Miscellaneous Enhancements
-------------------- */

/* 12.1 Responsive Tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: var(--spacing-lg);
}

th, td {
    padding: var(--spacing-sm);
    border: var(--border-width-thin) solid var(--color-neutral);
    text-align: left;
}

th {
    background-color: var(--color-accent-alt);
    color: var(--color-text-content);
}

tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.05);
}

tr:hover {
    background-color: var(--color-bg-valence-neutral);
}

@media (max-width: 600px) {
    table, thead, tbody, th, td, tr {
        display: block;
    }

    thead tr {
        display: none;
    }

    tr {
        margin-bottom: var(--spacing-md);
    }

    td {
        position: relative;
        padding-left: var(--spacing-md);
        text-align: right;
    }

    td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: var(--spacing-xs);
        font-weight: bold;
        text-align: left;
    }
}

/* 12.2 Responsive Video Embeds */
.video-responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    overflow: hidden;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* 12.3 Enhanced Forms */
form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    width: 100%;
    max-width: 500px;
    margin: var(--spacing-lg) 0;
}

input, textarea, select, button {
    padding: var(--spacing-sm);
    border: var(--border-width-thin) solid var(--color-neutral);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-body);
    transition: border-color var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function);
}

input:focus,
textarea:focus,
select:focus {
    border-color: var(--color-accent-alt);
    box-shadow: 0 0 5px var(--color-accent-alt);
    outline: none;
}

button {
    background: var(--color-accent-gradient);
    color: var(--color-text-content);
    cursor: pointer;
}

button:hover,
button:focus {
    background-color: var(--color-accent-alt);
    box-shadow: var(--shadow-hover);
}

button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

/* 12.4 Enhanced Tooltips */
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: var(--color-text-neutral);
    color: var(--color-text-content);
    text-align: center;
    border-radius: var(--border-radius-sm);
    padding: var(--spacing-xs) var(--spacing-sm);
    position: absolute;
    z-index: var(--z-index-tooltip);
    bottom: 125%; /* Position above the element */
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity var(--transition-speed-fast) var(--transition-function);
}

.tooltip:hover .tooltiptext,
.tooltip:focus .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%; /* Arrow at the bottom of tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--color-text-neutral) transparent transparent transparent;
}

/* 12.5 Enhanced Select Dropdowns */
select {
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><polygon points="0,0 10,0 5,5" fill="%23c9ada7"/></svg>');
    background-repeat: no-repeat;
    background-position: right var(--spacing-sm) center;
    background-size: 10px 5px;
    padding-right: 2rem;
}

select:hover,
select:focus {
    border-color: var(--color-accent-alt);
    box-shadow: 0 0 5px var(--color-accent-alt);
}

/* 12.6 Enhanced Input Types */
input[type="checkbox"],
input[type="radio"] {
    accent-color: var(--color-accent-alt);
}

input[type="range"] {
    width: 100%;
    height: 4px;
    background: var(--color-neutral);
    border-radius: var(--border-radius-sm);
    outline: none;
    transition: background var(--transition-speed-fast) var(--transition-function);
}

input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--color-accent-alt);
    cursor: pointer;
    transition: background var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function);
}

input[type="range"]::-webkit-slider-thumb:hover,
input[type="range"]::-webkit-slider-thumb:focus {
    background: var(--color-accent-main);
    transform: scale(1.2);
}

/* 12.7 Enhanced Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: var(--z-index-overlay);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition-speed-fast) var(--transition-function),
    visibility var(--transition-speed-fast) var(--transition-function);
}

.modal-overlay.active {
    opacity: 1;
    visibility: visible;
}

/* 12.8 Enhanced Loading Spinner */
.loader {
    border: var(--border-width-thin) solid #f3f3f3;
    border-top: var(--border-width-thin) solid var(--color-accent-alt);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: var(--spacing-lg) auto;
}

/* 12.9 Enhanced Responsive Images */
figure img {
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
    transition: transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function);
    cursor: pointer;
    max-height: 60vh;
    object-fit: cover;
}

figure img:hover,
figure img:focus {
    transform: scale(var(--interactive-hover-scale)) rotate(2deg);
    box-shadow: var(--shadow-hover);
}

/* 12.10 Responsive Grid Layout for Content */
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: var(--spacing-md);
    width: 100%;
    margin-bottom: var(--spacing-lg);
}

.grid-item {
    background-color: var(--color-bg-content);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
    transition: transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function),
    background-color var(--transition-speed-fast) var(--transition-function);
}

.grid-item:hover,
.grid-item:focus-within {
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
    background-color: var(--color-bg-valence-neutral);
}

/* --------------------
   13. Utility Classes
-------------------- */

/* Text Alignment */
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

/* Margins */
.mt-1 { margin-top: var(--spacing-xs); }
.mt-2 { margin-top: var(--spacing-sm); }
.mt-3 { margin-top: var(--spacing-md); }
.mb-1 { margin-bottom: var(--spacing-xs); }
.mb-2 { margin-bottom: var(--spacing-sm); }
.mb-3 { margin-bottom: var(--spacing-md); }

/* Paddings */
.pt-1 { padding-top: var(--spacing-xs); }
.pt-2 { padding-top: var(--spacing-sm); }
.pt-3 { padding-top: var(--spacing-md); }
.pb-1 { padding-bottom: var(--spacing-xs); }
.pb-2 { padding-bottom: var(--spacing-sm); }
.pb-3 { padding-bottom: var(--spacing-md); }
.px-1 { padding-left: var(--spacing-xs); padding-right: var(--spacing-xs); }
.px-2 { padding-left: var(--spacing-sm); padding-right: var(--spacing-sm); }
.py-1 { padding-top: var(--spacing-xs); padding-bottom: var(--spacing-xs); }
.py-2 { padding-top: var(--spacing-sm); padding-bottom: var(--spacing-sm); }

/* Display */
.d-block { display: block; }
.d-inline { display: inline; }
.d-inline-block { display: inline-block; }
.d-flex { display: flex; }
.flex-column { flex-direction: column; }
.flex-row { flex-direction: row; }

/* Justify and Align */
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.align-center { align-items: center; }

/* Visibility */
.hidden { display: none !important; }
.visible { display: block !important; }

/* Width and Height */
.w-100 { width: 100%; }
.h-100 { height: 100%; }

/* Background */
.bg-primary { background-color: var(--color-primary); }
.bg-accent { background-color: var(--color-accent-main); }

/* Text */
.text-primary { color: var(--color-primary); }
.text-accent { color: var(--color-accent-main); }

/* Borders */
.border-primary { border-color: var(--color-primary); }
.border-accent { border-color: var(--color-accent-main); }

/* Z-Index */
.z-tooltip { z-index: var(--z-index-tooltip); }
.z-overlay { z-index: var(--z-index-overlay); }
.z-modal { z-index: var(--z-index-modal); }

/* --------------------
   14. Media Queries Enhancements
-------------------- */

/* 14.1 Responsive Breakpoints */
@media (max-width: 1200px) {
    :root {
        --max-width-content: calc(min(85vw, 100%));
    }
}

@media (max-width: 768px) {
    body {
        padding: var(--spacing-lg) var(--spacing-md);
        font-size: 1.4rem;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    main.chapter {
        padding: var(--spacing-lg);
        background-attachment: scroll;
    }

    nav.chapter-navigation,
    .section-navigation {
        flex-direction: column;
        gap: var(--spacing-sm);
    }

    nav.chapter-navigation button,
    .section-navigation button {
        width: 100%;
    }

    .dialogue {
        padding: var(--spacing-xs);
        border-left: 3px solid var(--color-primary);
    }

    .custom-reflection {
        padding: var(--spacing-sm);
    }

    /* Responsive Styles for New Narrative Sections */
    .section.rhythm {
        padding: var(--spacing-sm);
        font-size: calc(var(--font-size-body) * 0.95);
    }

    .section.whisper {
        font-size: calc(var(--font-size-body) * 0.9);
    }

    .section.dialogue {
        padding: var(--spacing-sm);
    }
}

@media (prefers-reduced-motion: reduce) {
    *, *::before, *::after {
        animation: none !important;
        transition: none !important;
    }

    .emotion-curiosity:hover,
    .emotion-curiosity:focus,
    .custom-puzzle:hover,
    .custom-reflection:hover,
    .custom-mirror:hover,
    .custom-quiz:hover,
    .custom-tooltip:hover,
    .custom-rating:hover {
        transform: none !important;
        box-shadow: none !important;
        background-color: var(--color-bg-content) !important;
    }

    figure img:hover,
    figure img:focus {
        transform: none !important;
        box-shadow: var(--shadow-sm) !important;
    }

    /* Disable animations for narrative-specific sections */
    .section.rhythm,
    .section.whisper,
    .section.dialogue {
        transition: none !important;
    }
}

/* --------------------
   15. Animations (Consolidated)
-------------------- */

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes blink {
    0%, 100% { opacity: 0.8; }
    50% { opacity: 0; }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.01); }
    100% { transform: scale(1); }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* --------------------
   16. Utility Enhancements
-------------------- */

/* 16.1 Gradient Text */
.gradient-text {
    background: linear-gradient(90deg, var(--color-accent-main), var(--color-accent-alt));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

/* 16.2 Glassmorphism Effect */
.glassmorphism {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: var(--border-radius-md);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: var(--shadow-md);
}

/* 16.3 Neon Glow Effect */
.neon-glow {
    color: var(--color-accent-alt);
    text-shadow:
            0 0 5px var(--color-accent-alt),
            0 0 10px var(--color-accent-alt),
            0 0 20px var(--color-accent-alt),
            0 0 40px var(--color-accent-alt),
            0 0 80px var(--color-accent-alt);
    transition: text-shadow var(--transition-speed-fast) var(--transition-function);
}

.neon-glow:hover,
.neon-glow:focus {
    text-shadow:
            0 0 10px var(--color-accent-alt),
            0 0 20px var(--color-accent-alt),
            0 0 40px var(--color-accent-alt),
            0 0 80px var(--color-accent-alt),
            0 0 160px var(--color-accent-alt);
}

/* 16.4 Responsive Typography using clamp() */
:root {
    --font-size-responsive-h1: clamp(2rem, 5vw, 3rem);
    --font-size-responsive-h2: clamp(1.5rem, 4vw, 2.5rem);
    --font-size-responsive-h3: clamp(1.2rem, 3vw, 2rem);
}

/* Applying Responsive Typography */
h1 {
    font-size: var(--font-size-responsive-h1);
}

h2 {
    font-size: var(--font-size-responsive-h2);
}

h3 {
    font-size: var(--font-size-responsive-h3);
}

/* 16.5 Enhanced Button Animations */
button,
.custom-puzzle,
.custom-reflection,
.custom-mirror,
.custom-quiz,
.custom-tooltip,
.custom-rating {
    position: relative;
    overflow: hidden;
}

button::before,
.custom-puzzle::before,
.custom-reflection::before,
.custom-mirror::before,
.custom-quiz::before,
.custom-tooltip::before,
.custom-rating::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    background: rgba(255, 255, 255, 0.2);
    transition: all var(--transition-speed-fast) var(--transition-function);
    transform: skewX(-45deg);
}

button:hover::before,
button:focus::before,
.custom-puzzle:hover::before,
.custom-puzzle:focus::before,
.custom-reflection:hover::before,
.custom-reflection:focus::before,
.custom-mirror:hover::before,
.custom-mirror:focus::before,
.custom-quiz:hover::before,
.custom-quiz:focus::before,
.custom-tooltip:hover::before,
.custom-tooltip:focus::before,
.custom-rating:hover::before,
.custom-rating:focus::before {
    left: 100%;
    background: rgba(255, 255, 255, 0.2);
}

button::after,
.custom-puzzle::after,
.custom-reflection::after,
.custom-mirror::after,
.custom-quiz::after,
.custom-tooltip::after,
.custom-rating::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: var(--border-radius-md);
    transition: all var(--transition-speed-fast) var(--transition-function);
}

button:hover::after,
button:focus::after,
.custom-puzzle:hover::after,
.custom-puzzle:focus::after,
.custom-reflection:hover::after,
.custom-reflection:focus::after,
.custom-mirror:hover::after,
.custom-mirror:focus::after,
.custom-quiz:hover::after,
.custom-quiz:focus::after,
.custom-tooltip:hover::after,
.custom-tooltip:focus::after,
.custom-rating:hover::after,
.custom-rating:focus::after {
    box-shadow: 0 0 10px var(--color-accent-alt), 0 0 20px var(--color-accent-alt);
}

/* 16.6 Enhanced Tooltip Arrow */
custom-tooltip::after {
    border-color: var(--color-text-neutral) transparent transparent transparent;
}

@media (max-width: 768px) {
    custom-tooltip .tooltip {
        font-size: 0.8rem;
    }

    custom-tooltip::after {
        border-width: 4px;
    }
}

/* 16.7 Responsive Images */
figure img {
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
    transition: transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function);
    cursor: pointer;
    max-height: 60vh;
    object-fit: cover;
}

figure img:hover,
figure img:focus {
    transform: scale(var(--interactive-hover-scale)) rotate(2deg);
    box-shadow: var(--shadow-hover);
}

/* 16.8 Responsive Grid Layout */
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: var(--spacing-md);
    width: 100%;
    margin-bottom: var(--spacing-lg);
}

.grid-item {
    background-color: var(--color-bg-content);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
    transition: transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function),
    background-color var(--transition-speed-fast) var(--transition-function);
}

.grid-item:hover,
.grid-item:focus-within {
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
    background-color: var(--color-bg-valence-neutral);
}

/* 16.9 Enhanced Modal Styles */
custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    width: 90%;
    max-width: 600px;
    background-color: var(--color-bg-content);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-lg);
    z-index: var(--z-index-modal);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function),
    visibility var(--transition-speed-fast) var(--transition-function);
}

custom-modal[isOpen="true"] {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
}

custom-modal header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-sm) var(--spacing-md);
    border-bottom: var(--border-width-thin) solid var(--color-accent-alt);
}

custom-modal header h2 {
    font-size: var(--font-size-h2);
    color: var(--color-text-content);
}

custom-modal header button.close-button {
    background: none;
    border: none;
    color: var(--color-text-content);
    font-size: 1.5rem;
    cursor: pointer;
    transition: color var(--transition-speed-fast) var(--transition-function);
}

custom-modal header button.close-button:hover,
custom-modal header button.close-button:focus {
    color: var(--color-accent-alt);
}

custom-modal .modal-content {
    padding: var(--spacing-md);
    max-height: 70vh;
    overflow-y: auto;
}

custom-modal .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: var(--spacing-sm) var(--spacing-md);
    border-top: var(--border-width-thin) solid var(--color-accent-alt);
}

custom-modal .modal-footer button {
    margin-left: var(--spacing-sm);
}

/* 16.10 Enhanced Rating Stars */
custom-rating .star {
    transition: color var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function);
}

custom-rating .star.filled {
    color: var(--color-positive);
}

custom-rating .star:hover,
custom-rating .star:focus {
    color: var(--color-accent-alt);
    transform: scale(1.2);
}

/* 16.11 Interactive Symbol Styles */
.symbol {
    display: inline-block;
    padding: var(--spacing-xs) var(--spacing-sm);
    margin: var(--spacing-xs);
    background-color: var(--color-bg-content);
    border: var(--border-width-thin) solid var(--color-accent-main);
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    transition: background-color var(--transition-speed-fast) var(--transition-function),
    transform var(--transition-speed-fast) var(--transition-function),
    box-shadow var(--transition-speed-fast) var(--transition-function);
}

.symbol:hover,
.symbol:focus {
    background-color: var(--color-bg-valence-neutral);
    transform: scale(var(--interactive-hover-scale));
    box-shadow: var(--shadow-hover);
}

/* 16.12 Enhanced Loader Styles */
.loader {
    border: var(--border-width-thin) solid #f3f3f3;
    border-top: var(--border-width-thin) solid var(--color-accent-alt);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: var(--spacing-lg) auto;
}

/* --------------------
   17. Utility Classes Expansion
-------------------- */

/* 17.1 Text Utilities */
.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-nowrap {
    white-space: nowrap;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* 17.2 Background Utilities */
.bg-transparent {
    background-color: transparent;
}

.bg-white {
    background-color: #ffffff;
}

.bg-black {
    background-color: #000000;
}

/* 17.3 Border Utilities */
.border-none {
    border: none;
}

.border-top-none {
    border-top: none;
}

.border-bottom-none {
    border-bottom: none;
}

.border-radius-none {
    border-radius: 0;
}

/* 17.4 Display Utilities */
.d-block {
    display: block;
}

.d-inline {
    display: inline;
}

.d-inline-block {
    display: inline-block;
}

.d-flex {
    display: flex;
}

.d-grid {
    display: grid;
}

.d-none {
    display: none !important;
}

/* 17.5 Flexbox Utilities */
.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.justify-start {
    justify-content: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

/* 17.6 Grid Utilities */
.grid-cols-1 {
    grid-template-columns: repeat(1, 1fr);
}

.grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
}

.gap-1 {
    gap: var(--spacing-xs);
}

.gap-2 {
    gap: var(--spacing-sm);
}

.gap-3 {
    gap: var(--spacing-md);
}

/* 17.7 Positioning Utilities */
.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

.position-static {
    position: static;
}

/* 17.8 Overflow Utilities */
.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.overflow-scroll {
    overflow: scroll;
}

/* --------------------
   18. Dark Mode Enhancements (Additional)
-------------------- */

@media (prefers-color-scheme: dark) {
    /* Adjustments for custom elements */
    custom-tooltip .tooltiptext {
        background-color: var(--color-bg-content);
        color: var(--color-text-content);
    }

    custom-tooltip::after {
        border-color: var(--color-bg-content) transparent transparent transparent;
    }

    .loader {
        border-top: var(--border-width-thin) solid var(--color-accent-alt);
    }

    /* Adjust border colors for dark mode */
    .section.rhythm,
    .section.whisper,
    .section.dialogue {
        background-color: rgba(255, 255, 255, 0.05);
    }

    .section.rhythm:hover,
    .section.dialogue:hover,
    .section.whisper:hover {
        background-color: var(--color-bg-valence-neutral);
    }
}
